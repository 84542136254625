import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    $(document).ready(function(){
      $('#clients').owlCarousel({
          nav: false,
          dots: false,
          loop: true,
          autoplay: true,
          smartSpeed: 3000,
          responsive:{
            0:{
                items:2
            },
            480:{
                items:4
            },
            1000:{
                items:8
            }
          },
          navText:["<i class='fa-solid fa-chevron-left'></i>","<i class='fa-solid fa-chevron-right'></i>"]
        });
    });
    $(document).ready(function(){
      $('#work').owlCarousel({
          nav: true,
          dots: false,
          margin: 0,
          loop: true,
          responsive:{
            0:{
                items:1,
                margin:0,
            },
            640:{
                items:2,
                margin: 40,
            },
            1000:{
                items:3
            }
          },
          navText:["<i class='fa-solid fa-chevron-left'></i>","<i class='fa-solid fa-chevron-right'></i>"]
        });
    });
    $(document).ready(function(){
      $('#ourReviews').owlCarousel({
          items: 1,
          center: true,
          loop: true,
          autoplay: false,
          nav: true,
          responsive:{
            0:{
              autoWidth: false,
            },
            991:{
              autoWidth: true,
            }
          },
          navText:["<i class='fa-solid fa-chevron-left'></i>","<i class='fa-solid fa-chevron-right'></i>"]
        });
    });
  }
}
